<template>
  <div class="main">
    <div class="tab-box">
      <div class="item" @click="handleAudit(0)" :class="{ 'active': Audit === 0 }">
        文献评论
        <span v-if="literatureCount > 0">{{ literatureCount }}</span>
      </div>
      <div class="item" @click="handleAudit(1)" :class="{ 'active': Audit === 1 }">
        专题评论
        <span v-if="specialCount > 0">{{ specialCount }}</span>
      </div>
      <div class="item" @click="handleAudit(2)" :class="{ 'active': Audit === 2 }">
        十大学术热点评论
        <span v-if="tenCount > 0">{{ tenCount }}</span>
      </div>
      <div class="item" @click="handleAudit(3)" :class="{ 'active': Audit === 3 }">
        原创学术概念评论
        <span v-if="oriCount > 0">{{ oriCount }}</span>
      </div>
    </div>
    <div class="searchBox" @keydown.enter="getSearch">
      <!-- <el-input v-if='Audit === 0' clearable v-model='mineCommentListReqDto.referenceNumber'
      placeholder="请输入文献编号"></el-input>-->
      <el-input style="width: 200px;" v-if="Audit === 0" clearable v-model="mineCommentListReqDtos.title"
        placeholder="请输入文献题目"></el-input>
      <!-- <el-input v-if='Audit === 0' clearable v-model='mineCommentListReqDto.authorName' placeholder="请输入文献作者"></el-input> -->
      <el-input style="width: 200px;" v-if="Audit === 1" clearable v-model="mineCommentListReqDtos.subjectName"
        placeholder="请输入专题名称"></el-input>
      <el-input style="width: 200px;" v-if="Audit === 2" clearable v-model="mineCommentListReqDtos.acadamicName"
        placeholder="请输入学术热点名称"></el-input>
      <el-input style="width: 200px;" v-if="Audit === 3" clearable v-model="mineCommentListReqDtos.orginalName"
        placeholder="请输入原创学术概念名称"></el-input>
      <el-input style="width: 200px;" clearable v-model="mineCommentListReqDtos.comment"
        placeholder="请输入评论内容"></el-input>
      <el-button @click="getSearch" type="primary" v-preventClick style="margin-left: 10px">查询</el-button>
    </div>
    <div></div>
    <div class="context">
      <div class="table">
        <el-table v-if="total > 0" :cell-style="tableCellStyle" :data="tableData" :header-cell-style="tableHead"
          class="elTab" max-height="600" ref="tables" align="center">
          <el-table-column align="center" label="序号" type="index" width="60">
            <template slot-scope="scope">{{ (mineCommentListReqDto.page - 1) * mineCommentListReqDto.pageRow +
              scope.$index + 1 }}</template>
          </el-table-column>
          <el-table-column show-overflow-tooltip v-if="Audit === 0" width="150" prop="title" label="文献题目"
            align="center">
            <template #default="scoped">
              <span @click="goBackliterature(scoped.row)">{{ scoped.row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip v-if="Audit === 1" prop="subjectName" label="专题名称" align="center">
            <template #default="scoped">
              <span @click="goBackSpecial(scoped.row)">{{ scoped.row.subjectName }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip v-if="Audit === 2" prop="academicHotWordsName" label="学术热点名称"
            align="center">
            <template #default="scoped">
              <span @click="goBackAcademic(scoped.row)">{{ scoped.row.academicHotWordsName }}</span>
            </template>
          </el-table-column>
          <el-table-column show-overflow-tooltip v-if="Audit === 3" prop="originalScholarshipName" label="原创学术概念名称"
            align="center">
            <template #default="scoped">
              <span @click="goBackOriginal(scoped.row)">{{ scoped.row.originalScholarshipName }}</span>
            </template>
          </el-table-column>
          <el-table-column width="220" prop="comment" label="评论内容" align="center">
            <template slot-scope="scope">
              <el-popover placement="bottom" trigger="hover">
                <template>
                  <div class="set-popper" v-html="scope.row.comment"></div>
                </template>
                <div slot="reference" class="set-content">{{ scope.row.comment }}</div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column prop="state" label="审核结果" align="center" width="100">
            <template #default="scoped">
              <span v-if="scoped.row.state === 0" style="color: #FF7100; display: block; padding: 3px 5px;">审核中</span>
              <span v-if="scoped.row.state === 1" style="color: #1AB41A; display: block; padding: 3px 5px;">通过</span>
              <span v-if="scoped.row.state === 2" style="color: #F03838; display: block; padding: 3px 5px;">未通过</span>
            </template>
          </el-table-column>
          <el-table-column width="130" prop="rejectReason" label="拒绝理由" align="center">
            <template #default="scoped">
              <el-popover v-if="scoped.row.rejectReason !== null && scoped.row.rejectReason !== ''" placement="bottom"
                trigger="hover">
                <template>
                  <div class="set-popper" v-html="scoped.row.rejectReason"></div>
                </template>
                <div slot="reference" class="set-content"
                  v-if="scoped.row.rejectReason !== null && scoped.row.rejectReason !== ''">
                  {{
                    scoped.row.rejectReason
                  }}
                </div>
              </el-popover>
              <div slot="reference" class="set-content"
                v-if="scoped.row.rejectReason === null || scoped.row.rejectReason === ''">-
              </div>
            </template>
          </el-table-column>
          <el-table-column width="120" show-overflow-tooltip prop="createTime" label="评论发布时间"
            align="center"></el-table-column>
          <el-table-column width="100" fixed="right" align="center" label="操作">
            <template slot-scope="scope">
              <el-button v-if="scope.row.state === 2" type="text" size="small" @click="goEdit(scope.row)">编辑</el-button>
              <el-button type="text" size="small" @click="goDetele(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="empty" v-else>
          <img src="../../assets/images/home/nodataside.png" alt />
          <span>暂无评论,快去发表您的评论吧</span>
        </div>
        <div class="elPagination" v-if="total > 0">
          <el-pagination :page-sizes="[10, 20, 30, 40]" :page-size="mineCommentListReqDto.pageRow"
            layout="total, sizes, prev, pager, next, jumper" :total="total" @current-change="handleCurrentChange"
            :current-page="mineCommentListReqDto.page" @size-change="handleSizeChange" background></el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :lock-scroll="false" title="评论编辑" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <el-input @change="commentFilter" type="textarea" :rows="4" placeholder="请输入内容" v-model="textarea" maxlength="500"
        show-word-limit></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="sendComment">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      literatureCount: '',
      specialCount: '',
      tenCount: '',
      oriCount: '',
      words: false,
      Audit: 0,
      start: '',
      end: '',
      dialogVisible: false,
      textarea: '',
      mineCommentListReqDto: {
        authorName: '',
        comment: '',
        endTime: '',
        page: 1,
        pageRow: 10,
        referenceNumber: '',
        startTime: '',
        title: '',
        subjectName: '',
        acadamicName: '',
        orginalName: ''
      },
      mineCommentListReqDtos: {
        authorName: '',
        comment: '',
        endTime: '',
        page: 1,
        pageRow: 10,
        referenceNumber: '',
        startTime: '',
        title: '',
        subjectName: '',
        acadamicName: '',
        orginalName: ''
      },
      tableData: [],
      total: 0,
      id: '' // 评论id
    }
  },
  mounted () {
    document.title = this.$route.meta.title
    this.getAll()
    this.Audit = 0
  },
  methods: {
    // 点击去文献
    goBackliterature (row) {
      const routeData = this.$router.resolve({
        path: `/mainDetails?id=${row.literId}&referenceNumber=${row.referenceNumber}&path=${row.literPath}`
      })
      window.open(routeData.href, '_blank')
    },
    // 点击去专题
    goBackSpecial (row) {
      const routeData = this.$router.resolve({
        path: `/disciplinedetail?id=${row.subjectId}`
      })
      window.open(routeData.href, '_blank')
    },
    // 点击去学术
    goBackAcademic (row) {
      const routeData = this.$router.resolve({
        path: `/tenscienceInfo?id=${row.academicHotWordsId}`
      })
      window.open(routeData.href, '_blank')
    },
    // 点击去原创概念
    goBackOriginal (row) {
      const routeData = this.$router.resolve({
        path: `/originalDetail?id=${row.originalScholarshipId}`
      })
      window.open(routeData.href, '_blank')
    },
    // 专门写一个事件用来手动调用原创概念而不走系统
    async getHandOriComment () {
      const res = await this.$http({
        url: '/userinfo/academicConceptsComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.tableData = res.data.data.acaConceptsList
      this.total = res.data.data.count
      this.oriCount = res.data.data.count
    },
    // 专门写一个事件用来手动调用而不走系统
    async getHandTenComment () {
      const res = await this.$http({
        url: '/userinfo/acaComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.tableData = res.data.data.acaList
      this.total = res.data.data.count
      this.tenCount = res.data.data.count
    },
    // 专门写一个事件用来调用专题评论而不是被系统调用
    async getSpecialComment () {
      const res = await this.$http({
        url: '/userinfo/subComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.tableData = res.data.data.subList
      this.total = res.data.data.count
      this.specialCount = res.data.data.count
    },
    // 专门写一个事件用来调用专题评论而不是被系统调用
    async getFirstComment () {
      const res = await this.$http({
        url: '/userinfo/mineComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.tableData = res.data.data.list
      this.total = res.data.data.count
      this.literatureCount = res.data.data.count
    },
    // 获取数据
    async getCount () {
      const res0 = await this.$http({
        url: '/userinfo/mineComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.literatureCount = res0.data.data.count
      const res1 = await this.$http({
        url: '/userinfo/subComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.specialCount = res1.data.data.count
      const res2 = await this.$http({
        url: '/userinfo/acaComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.tenCount = res2.data.data.count
      const res3 = await this.$http({
        url: '/userinfo/academicConceptsComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })
      this.oriCount = res3.data.data.count
    },
    async getAll () {
      // await this.getSubData(1)
      // await this.handleAlready(1)
      // await this.getOriData(1)
      this.Audit = 0
      await this.getAllData()
    },
    getSearch () {
      this.mineCommentListReqDto = JSON.parse(JSON.stringify(this.mineCommentListReqDtos))
      this.mineCommentListReqDto.page = 1
      // this.mineCommentListReqDto.title = this.mineCommentListReqDto.title.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      // this.mineCommentListReqDto.subjectName = this.mineCommentListReqDto.subjectName.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      // this.mineCommentListReqDto.orginalName = this.mineCommentListReqDto.orginalName.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      // this.mineCommentListReqDto.comment = this.mineCommentListReqDto.comment.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5\（\）\·\:\"\”\“\、\：\？\《\》]/g, '')
      if (this.Audit === 0) {
        this.getAllData()
      }
      if (this.Audit === 1) {
        this.handleAlready(1)
      }
      if (this.Audit === 2) {
        this.getSubData(1)
      }
      if (this.Audit === 3) {
        this.getOriData(1)
      }
    },
    // 敏感词过滤
    async commentFilter () {
      const { data: res } = await this.$http({
        url: '/comment/existsSensitiveWords',
        method: 'post',
        data: {
          desc: this.textarea
        }
      })
      if (res.code !== 200) return this.$message.error(res.msg)
      if (res.code === 200) {
        if (res.data.length !== 0) {
          this.words = true
          this.$message.error(
            '您的评论中包含敏感词：' + res.data + '请重新编辑，发布您的评论哦。'
          )
        } else {
          this.words = false
        }
      }
    },
    handleAudit (val) {
      this.mineCommentListReqDto.page = 1
      this.mineCommentListReqDto.title = ''
      this.mineCommentListReqDto.subjectName = ''
      this.mineCommentListReqDto.acadamicName = ''
      this.mineCommentListReqDto.comment = ''
      this.mineCommentListReqDto.orginalName = ''
      this.Audit = val
      if (val == 0) {
        this.getFirstComment()
      } else if (val == 1) {
        this.getSpecialComment()
      } else if (val == 2) {
        this.getHandTenComment()
      } else if (val == 3) {
        this.getHandOriComment()
      }
      // this.getCount()
    },
    async handleAlready (val) {
      this.mineCommentListReqDto.page = val
      const res = await this.$http({
        url: '/userinfo/subComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })

      this.Audit = 1
      this.tableData = res.data.data.subList
      this.total = res.data.data.count
      this.specialCount = res.data.data.count
    },
    handleStaryears (val) {
      this.mineCommentListReqDto.startTime = val
    },
    handleEndyears (val) {
      this.mineCommentListReqDto.endTime = val
    },
    // 删除
    async goDetele (row) {
      this.$confirm('此操作将永久删除该评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        lockScroll: false
      })
        .then(async () => {
          if (this.Audit === 0) {
            const res = await this.$http({
              url: '/comment/delete',
              method: 'POST',
              data: {
                id: row.id,
                parentId: row.parentId
              }
            })
            if (res.data.code !== 200) {
              return this.$message({
                type: 'warning',
                message: '删除失败'
              })
            }
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getAllData()
          }
          if (this.Audit === 1) {
            const res = await this.$http({
              url: '/subjectComment/delete',
              method: 'POST',
              data: {
                id: row.id,
                parentId: row.parentId
              }
            })
            if (res.data.code !== 200) {
              return this.$message({
                type: 'warning',
                message: '删除失败'
              })
            }
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getAllData()
          }
          if (this.Audit === 2) {
            const res = await this.$http({
              url: '/academicComment/delete',
              method: 'POST',
              data: {
                id: row.id,
                parentId: row.parentId
              }
            })
            if (res.data.code !== 200) {
              return this.$message({
                type: 'warning',
                message: '删除失败'
              })
            }
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getAllData()
          }
          if (this.Audit === 3) {
            const res = await this.$http({
              url: '/originalScholarship/delete',
              method: 'POST',
              data: {
                id: row.id,
                parentId: row.parentId
              }
            })
            if (res.data.code !== 200) {
              return this.$message({
                type: 'warning',
                message: '删除失败'
              })
            }
            this.$message({
              type: 'success',
              message: '删除成功'
            })
            this.getAllData()
          }
        })
        .catch(() => { })
    },
    handleClose () {
      this.dialogVisible = false
    },
    // 保存
    async sendComment () {
      await this.commentFilter()
      if (this.words === true) {
        return this.$message({
          type: 'warning',
          message: '您的评论包含敏感词,请编辑后操作'
        })
      }
      if (this.Audit === 0) {
        const res = await this.$http({
          url: '/userinfo/updateComment',
          method: 'POST',
          data: {
            comment: this.textarea,
            id: this.id
          }
        })
        if (res.data.code !== 200) {
          return this.$message({
            type: 'warning',
            message: '修改失败'
          })
        }
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.textarea = ''
        this.id = ''
        this.dialogVisible = false
        this.getAllData()
      }
      if (this.Audit === 1) {
        const res = await this.$http({
          url: '/subjectComment/addOrUpdate',
          method: 'POST',
          data: {
            comment: this.textarea,
            id: this.id
          }
        })
        if (res.data.code !== 200) {
          return this.$message({
            type: 'warning',
            message: '修改失败'
          })
        }
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.textarea = ''
        this.id = ''
        this.dialogVisible = false
        this.getSpecialComment()
      }
      if (this.Audit === 2) {
        const res = await this.$http({
          url: '/academicComment/addOrUpdate',
          method: 'POST',
          data: {
            comment: this.textarea,
            id: this.id
          }
        })
        if (res.data.code !== 200) {
          return this.$message({
            type: 'warning',
            message: '修改失败'
          })
        }
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.textarea = ''
        this.id = ''
        this.dialogVisible = false
        this.getHandTenComment()
      }
      if (this.Audit === 3) {
        const res = await this.$http({
          url: '/originalScholarship/addOrUpdate',
          method: 'POST',
          data: {
            comment: this.textarea,
            id: this.id
          }
        })
        if (res.data.code !== 200) {
          return this.$message({
            type: 'warning',
            message: '修改失败'
          })
        }
        this.$message({
          type: 'success',
          message: '修改成功'
        })
        this.textarea = ''
        this.id = ''
        this.dialogVisible = false
        this.getHandOriComment()
      }
    },
    // 打开编辑框
    goEdit (row) {
      this.dialogVisible = true
      this.id = row.id
      this.textarea = row.comment
    },
    // 切换每页显示条数
    handleSizeChange (val) {
      this.mineCommentListReqDto.pageRow = val
      if (this.Audit === 0) {
        this.getAllData()
      }
      if (this.Audit === 1) {
        this.handleAlready(val)
      }
      if (this.Audit === 2) {
        this.getSubData(val)
      }
      if (this.Audit === 3) {
        this.getOriData(val)
      }
    },
    // 切换分页
    handleCurrentChange (val) {
      this.mineCommentListReqDto.page = val

      if (this.Audit === 0) {
        this.getAllData()
      }
      if (this.Audit === 1) {
        this.handleAlready(val)
      }
      if (this.Audit === 2) {
        this.getSubData(val)
      }
      if (this.Audit === 3) {
        this.getOriData(val)
      }
    },
    tableHead () {
      return 'background-color: #f2f2f2;text-align:center;color:#9c2526'
    },
    tableCellStyle () {
      // return 'border: 1px;text-align: center'
    },
    async getAllData () {
      // this.mineCommentListReqDto.page = 1
      if (this.Audit === 0) {
        const res = await this.$http({
          url: '/userinfo/mineComment',
          method: 'POST',
          data: this.mineCommentListReqDto
        })
        this.tableData = res.data.data.list
        this.total = res.data.data.count
        this.literatureCount = res.data.data.count
        this.specialCount = res.data.data.subCount
        this.tenCount = res.data.data.hotCount
        this.oriCount = res.data.data.cnacademicCount
      }
      // if (this.Audit === 1) {
      //   this.handleAlready(1)
      // }
      // if (this.Audit === 2) {
      //   this.getSubData(1)
      // }
      // if (this.Audit === 3) {
      //   this.getOriData(1)
      // }
    },
    // 十大学术评论
    async getSubData (val) {
      this.mineCommentListReqDto.page = val
      const res = await this.$http({
        url: '/userinfo/acaComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })

      this.Audit = 2
      this.tableData = res.data.data.acaList
      this.total = res.data.data.count
      this.tenCount = res.data.data.count
    },
    async getOriData (val) {
      this.mineCommentListReqDto.page = val
      const res = await this.$http({
        url: '/userinfo/academicConceptsComment',
        method: 'POST',
        data: this.mineCommentListReqDto
      })

      this.Audit = 3
      this.tableData = res.data.data.acaConceptsList
      this.total = res.data.data.count
      this.oriCount = res.data.data.count
    }
  }
}
</script>
<style scoped lang="less">
* {
  box-sizing: border-box;
}

/deep/.el-table .cell.el-tooltip {
  white-space: nowrap;
  min-width: 100% !important;
  /* text-align: center; */
}

/deep/.el-table {
  width: 100%;

  .el-table__header-wrapper table,
  .el-table__body-wrapper table {
    width: 100% !important;
  }

  .el-table__body,
  .el-table__footer,
  .el-table__header {
    // table-layout: auto;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
}

.main {
  overflow-y: auto;
  background: #fff;
  height: calc(100%-80px);
  width: 1156px;
  padding: 20px;

  .tab-box {
    display: flex;
    align-items: center;

    .item {
      color: #444;
      font-size: 14px;
      text-align: center;
      // padding: 8px 12px;
      margin-right: 60px;
      cursor: pointer;
      // border-radius: 4px;
      border-bottom: 2px solid transparent;
      font-size: 18px;
      height: 35px;

      span {
        display: inline-block;
        padding: 0px 4px;
        background-color: #f03838;
        color: #fff;
        border-radius: 8px;
        min-width: 32px;
        // height: 16px;
        // line-height: 16px;
      }

      &.active {
        border-bottom: 2px solid #9c2526;
        // background: #9c2526;
        color: #9c2526;
      }
    }
  }

  .searchBox {
    margin-top: 20px;
    margin-bottom: 20px;

    .el-input {
      width: 150px;
      height: 40px;
      margin-right: 10px;
    }
  }

  .context {
    height: calc(100% - 40px);
    width: 100%;
    margin-top: 20px;

    .header {
      height: 70px;
      align-items: center;

      .left {
        h5 {
          margin-right: 30px;
          // width: 84px;
          font-size: 14px;
          font-weight: 400;
          color: #9c2526;

          span {
            font-size: 18px;
            font-weight: 500;
            color: #9c2526;
          }
        }
      }

      // .right {}
    }

    .table {
      height: calc(100% - 90px);
      width: 100% !important;

      /deep/.el-table {
        height: calc(100% - 50px) !important;
        width: 100% !important;
      }

      .empty {
        margin-top: 65px;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          width: 232px;
          height: 114px;
        }

        span {
          color: #999;
          font-size: 14px;
        }
      }

      .elPagination {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
      }
    }
  }
}

/deep/.el-dialog__body {
  padding: 10px !important;

  .el-card {
    border-radius: 0 !important;
    box-shadow: none !important;
    border: none !important;
    border-bottom: 3px solid #9c2526 !important;

    &:last-child {
      border-bottom: 0px !important;
    }

    /deep/ .el-card__header {
      padding: 0 !important;
      border: none !important;
    }
  }
}

/deep/ .el-card__header {
  padding: 0 !important;
  border: none !important;
  margin: 10px 0 !important;
}

.dialogTitle {
  border-left: 2px solid #9c2526;
  font-size: 16px;
  font-weight: 550;
  display: block;
  padding: 0 15px;
  line-height: 30px;
  margin-top: 5px;
}

.authorName {
  font-size: 20px;
  font-weight: 550;
  margin-right: 20px;
  // margin: 10px 0;
}

.authorInfo {
  display: flex;
  justify-content: space-evenly;
}

.el-form-item {
  margin-left: 12px;

  // /deep/label {
  //   position: relative;
  // }

  // /deep/label::before {
  //   width: 10px;
  //   height: 10px;
  //   background-color: #9c2526;
  // }
}

// /deep/.cell.el-tooltip {
//   width: 100% !important;
// }

.el-tag {
  margin-right: 5px;
}

.authorList {
  margin-left: 20px;
}

.set-popper {
  width: 431px;
}

.set-content {
  cursor: default;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.el-table {
  width: 1156px !important;
}

/deep/.el-table__body {
  width: 1156px !important;
}

/deep/.el-table__row:hover {
  color: #9c2526;
  cursor: pointer;
}
</style>
